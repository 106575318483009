import CreateRIDCContractorPayload from "@/dto/request/ridc/CreateRIDCContractorPayload";

export default class CreateRIDCPayload {

    companyId!: number;
    toBeFilledByCustomer = false;
    contractors?: Array<CreateRIDCContractorPayload>;

    constructor(init?: Partial<CreateRIDCPayload>) {
        Object.assign(this, init);
    }

}
