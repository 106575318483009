



























import { Vue, Component, Prop } from "vue-property-decorator";
import CompanyDTO from "@/dto/company/CompanyDTO";
import CreateRIDCPayload from "@/dto/request/ridc/CreateRIDCPayload";
import {namespace} from "vuex-class";
import RIDCService from "@/services/request/RIDCService";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import { processError } from "@/utils/ComponentUtils";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import PublicRIDC from "@/components/request/ridc/PublicRIDC.vue";
import CreateRIDCContractorPayload from "@/dto/request/ridc/CreateRIDCContractorPayload";
import Workspaces from "@/state/Workspaces";
import RouteNames from "@/router/RouteNames";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
  components: { PublicRIDC, PortalCheckbox, SimpleCompanySearch }
})
export default class CreateRIDC extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Auth.Getter
  private isAdmin!: boolean;

  @Prop()
  onCreated!: () => void

  private payload = new CreateRIDCPayload();

  private selectCompany(company: CompanyDTO) {
    Vue.set(this.payload, 'companyId', company.id);
  }

  mounted() {
    if (!this.isAdmin || (this.isAdmin && this.$route.name === RouteNames.HR_REQUESTS)) {
      Vue.set(this.payload, 'companyId', Workspaces.getCurrent.id);
    }
  }

  create() {
    this.startLoading();
    RIDCService.create(this.payload).then(
        ok => {
          this.stopLoading();
          this.onCreated();
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    )
  }

  addContractor(newContractor: CreateRIDCContractorPayload) {
    if (!this.payload.contractors) {
      Vue.set(this.payload, 'contractors', [])
    }
    this.payload.contractors?.push(newContractor)
  }

}
