
















































import {Component, Prop, Vue} from "vue-property-decorator";
import {CounterpartyType} from "@/constants/CounterpartyType";
import StaffRegistrationModal from "@/components/payroll/business/employments/employee/EmployeeRegistrationModal.vue";
import CompanySearch from "@/components/common/CompanySearch.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import PersonSearch from "@/components/payroll/business/employments/employee/PersonSearch.vue";
import CreatePersonRequestDTO from "@/dto/person/CreatePersonRequestDTO";
import PersonDTO from "@/dto/person/PersonDTO";
import RegisterContractorPayload from "@/components/payroll/business/employments/contractors/RegisterContractorPayload";
import {namespace} from "vuex-class";
import {ifValid} from "@/utils/ComponentUtils";
import ContractorService from "@/components/payroll/business/employments/contractors/ContractorService";
import Workspace from "@/dto/auth/Workspace";
import Workspaces from "@/state/Workspaces";
import CustomerAccounts from "@/dto/CustomerAccounts";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import CreateCompanyRequestDTO from "@/dto/company/CreateCompanyRequestDTO";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import BranchDto from "@/components/profile/branches/BranchDtos";
import CompanyService from "@/services/CompanyService";
import PortalRadio from "@/components/v2/common/PortalRadio.vue";
import {ContractType, contractTypeOptions} from "@/constants/EmploymentType";

const AppModule = namespace("App");

@Component({
	methods: {contractTypeOptions: contractTypeOptions},
	components: {PortalRadio, PortalSelect, BTPortalCollapse, PortalCheckbox, PersonSearch, PortalDate, CompanySearch, StaffRegistrationModal},
	computed: {
		ContractType() {
			return ContractType
		},
		SelectOption() {
			return SelectOption
		},
		CounterpartyType() {
			return CounterpartyType
		}
	}
})
export default class ContractorRegistrationModal extends Vue {

	private payload: RegisterContractorPayload = new RegisterContractorPayload()

	private createPersonRequest: CreatePersonRequestDTO = new CreatePersonRequestDTO();

	private createCompanyRequest: CreateCompanyRequestDTO = new CreateCompanyRequestDTO();

	private workspace: Workspace = Workspaces.getCurrent;

	private branches: Array<BranchDto> = [];

	private message: string | null = null;

	private successful = false;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	@Prop()
	private callback!: () => void;

	private loadBranches() {
		this.startLoading();
		CompanyService.getBranches(Workspaces.getCurrent.id).then(
			res => {
				this.branches = [...res.data];
				this.stopLoading()
			},
			err => this.processError(err)
		)
	}

	private loadHeadQuarter() {
		this.startLoading();
		CompanyService.getHeadQuarter(Workspaces.getCurrent.id).then(
			res => {
				this.branches.push(res.data)
				this.stopLoading()
			},
			err => this.processError(err)
		)
	}

	private processError(err: any) {
		this.successful = false;
		this.message = this.$t(ErrorLocalePathUtils.getPathForError(err)) as string;
		this.stopLoading();
	}

	public mounted(): void {
		this.payload.employerId = this.workspace.id;
		this.loadBranches();
		this.loadHeadQuarter();
	}

	private applyCompanySelect(customer: CompanyDTO) {
		this.payload.contractorId = customer.id;
	}

	private applyPersonSelect(customer: PersonDTO) {
		this.payload.contractorId = customer.id;
	}

	private register() {
		if (!this.payload.contractorId) {
			this.message = "Select a contractor from the drop-down list"
			this.successful = false;
		} else {
			ifValid(this, () => {
				this.startLoading();
				ContractorService.registerContractor(this.payload).then(
					() => {
						this.stopLoading();
						this.callback();
						this.$modal.hideAll();
					},
					err => this.errorHandling(err)
				)
			})
		}
	}

	private errorHandling(err: any) {
		console.log(err?.response?.data.param)
		this.successful = false;
		let systemCode = err?.response?.data.systemCode;
		let param = err?.response?.data.param;
		let person: PersonDTO;
		let customerAccounts: CustomerAccounts;
		this.$modal.hide("hide")
		switch (systemCode) {
			case 40023:
			case 40033:
				person = param;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(err),
					{ssn: person.ssn, lastName: person.lastName, firstName: person.firstName, tin: person.nationalTin}) as string;
				break;
			case 40025:
				customerAccounts = param;
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(err),
					{
						lastName: customerAccounts.person?.lastName,
						firstName: customerAccounts.person?.firstName,
						listCompanyNames: this.handleCompaniesName(customerAccounts.companies)
					}) as string;
				break
			default:
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(err), param) as string;
		}
		this.stopLoading();
	}

	private handleCompaniesName(companies: Array<CompanyDTO>): string {
		let res = "";
		for (let i = 0; i < companies.length; i++) {
			res += companies[i].name.concat(i == companies.length - 1 ? "" : ", ");
		}
		return res;
	}
}

