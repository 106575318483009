var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticStyle:{"font-size":"30px"}},[_vm._v("Contractors")]),(_vm.contractors.length !== 0)?_c('div',[_c('div',{staticClass:"mb-3"},[_c('portal-input',{attrs:{"label":"Name","name":"filter_name","placeholder":"Enter the contractor's name"},model:{value:(_vm.filter.name),callback:function ($$v) {_vm.$set(_vm.filter, "name", $$v)},expression:"filter.name"}}),_c('portal-select',{attrs:{"label":"Contractor type","name":"filter_type","options":_vm.buildOptions()},scopedSlots:_vm._u([{key:"defaultOptionTitle",fn:function(){return [_c('option',{attrs:{"selected":""},domProps:{"value":null}},[_vm._v("All")])]},proxy:true}],null,false,4213966071),model:{value:(_vm.filter.contractorType),callback:function ($$v) {_vm.$set(_vm.filter, "contractorType", $$v)},expression:"filter.contractorType"}}),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('button',{staticClass:"portal-button",on:{"click":_vm.reset}},[_vm._v("Reset")]),_c('button',{staticClass:"portal-button",on:{"click":_vm.search}},[_vm._v("Search")])])],1),_c('div',{staticClass:"admin_table_border"},[_vm._m(0),_vm._l((_vm.contractors),function(contractor,index){return _c('router-link',{key:index,staticClass:"d-flex align-items-center admin_table admin_list_item admin_list_item_border table-row-clickable",staticStyle:{"display":"block","width":"100%"},attrs:{"to":{
                 name: contractor.counterpartyType === _vm.CounterpartyType.COMPANY ?
                  _vm.RouteNames.PAYROLL_COMPANY_BUSINESS_CONTRACTOR
                  :
                  _vm.RouteNames.PAYROLL_COMPANY_INDIVIDUAL_CONTRACTOR,
                 params: {
                   companyId: _vm.$wss.getCurrent.id,
                   contractorId: contractor.id
                 }
               }}},[_c('div',{staticClass:"col-md-3 p-2",staticStyle:{"text-align":"center"}},[_c('span',[_vm._v(_vm._s(contractor.details.name))])]),_c('div',{staticClass:"col-md-3 p-2",staticStyle:{"text-align":"center"}},[_c('span',[_vm._v(_vm._s(contractor.counterpartyType))])]),_c('div',{staticClass:"col-md-3 p-2",staticStyle:{"text-align":"center"}},[_c('span',[_vm._v(_vm._s(_vm.$dateUtils.formatDateToAmericanFormat(contractor.employmentStart)))])]),_c('div',{staticClass:"col-md-3 p-2",staticStyle:{"text-align":"center"}},[_c('span',[_vm._v(_vm._s(contractor.status))])])])})],2),_c('pagination-component',{attrs:{"page-number":_vm.filter.pageNumber + 1,"total-pages":_vm.totalPages,"on-change-page":function (page) { return this$1.changePage(page); }}})],1):_c('div',{staticClass:"m-4 d-flex justify-content-center"},[_c('h3',{staticStyle:{"color":"#436356"}},[_vm._v("No contractors")])]),(_vm.createRequestMode)?_c('div',[_c('create-r-i-d-c',{attrs:{"onCreated":_vm.reload}})],1):(_vm.action == null)?_c('div',{staticClass:"d-flex align-items-center justify-content-end mb-3"},[(!_vm.isClient)?_c('button',{staticClass:"portal-button",on:{"click":function($event){$event.preventDefault();return _vm.addContractor.apply(null, arguments)}}},[_vm._v(" Register a contractor ")]):_c('button',{staticClass:"portal-button",on:{"click":function($event){$event.preventDefault();_vm.createRequestMode = true}}},[_vm._v(" Request new contractors registration ")])]):_vm._e(),(_vm.message)?_c('div',{staticClass:"alert auth-form-element",class:_vm.successful ? 'alert-success' : 'alert-danger'},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center admin_title"},[_c('div',{staticClass:"col-md-3 p-2 text-center"},[_vm._v(" Name ")]),_c('div',{staticClass:"col-md-3 p-2 text-center"},[_vm._v(" Type ")]),_c('div',{staticClass:"col-md-3 p-2 text-center"},[_vm._v(" Registration date ")]),_c('div',{staticClass:"col-md-3 p-2 text-center"},[_vm._v(" Status ")])])}]

export { render, staticRenderFns }