export default class CreateRIDCContractorPayload {

  name: string | null = null;
  phone: string | null = null;
  email: string | null = null;

  constructor(init?: Partial<CreateRIDCContractorPayload>) {
    Object.assign(this, init);
  }

}
